.App {
  display: flex;
  flex-direction: column;
  min-height: min-content;
}

.PrivateTabIndicator-root-1.PrivateTabIndicator-colorSecondary-3.MuiTabs-indicator
{
  background-color: #8f9688;
  height: 2px;
}

li
{
  list-style: none;
}

.footer
{
  padding: 3;
  margin-top: 'auto';
  background-color: #8f9688;
}

.main
{
  margin-top: 8px;
  margin-bottom: 2px;
}

/* barlow-condensed-regular - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/barlow-condensed-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/barlow-condensed-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/barlow-condensed-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/barlow-condensed-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/barlow-condensed-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/barlow-condensed-v5-latin-regular.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}